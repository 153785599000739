import { mappings, expiryDays } from '../../surveySplitConfig'

export function getCookie(cookieName: string) {
  const cookieSearch = document.cookie.match(
    '(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)'
  )

  if (cookieSearch) {
    return cookieSearch[2]
  } else {
    return null
  }
}

export function getSurveySplitCookie(surveyId: string) {
  const cookieName = `${surveyId}_surveySimplificationTrial`
  const cookieValue = getCookie(cookieName)

  const date = new Date()
  date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000)

  // We check if the cookie already exists, and we create it if it doesn't
  if (cookieValue === null) {
    const isSurveySimplificationTrial: boolean =
      verifyCustomerSurveySimplificationTrial(surveyId)
    document.cookie = `${cookieName}=${isSurveySimplificationTrial}; expires=${date.toUTCString()}; path=/; SameSite; Secure`
    return isSurveySimplificationTrial
  }

  return cookieValue === 'true'
}

export const verifyCustomerSurveySimplificationTrial = (
  surveyId: string
): boolean => {
  // chance = Math.random() * 100 returns a number between 0 (inclusive) and 100 (exclusive)
  // If chance < redirectChance = true, then the customer will be entered into trial
  const chance = Math.random() * 100
  return chance < mappings[surveyId].redirectChance
}

export function getTrialSurveyId(surveyId: string) {
  // We only run the survey split logic if surveyId is in the configured mappings
  if (Object.keys(mappings).includes(surveyId)) {
    const isSurveySplit = getSurveySplitCookie(surveyId)

    if (isSurveySplit) {
      return mappings[surveyId].redirectSurveyId ?? surveyId
    } else {
      return surveyId
    }
  } else {
    return surveyId
  }
}
